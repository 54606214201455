var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"font",attrs:{"text":"Lube Deliveries"}})],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"ml-n1",attrs:{"cols":"12","sm":"12"}},[_c('v-chip-group',{staticClass:"font ",attrs:{"light":"","show-arrows":""},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}},_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"box-shadow-light font",attrs:{"label":"","color":"white","value":tag,"active-class":"primary--text"}},[_c('span',{staticClass:"font-weight-bold text-uppercases"},[_vm._v(_vm._s(tag))])])}),1)],1)],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light font ",attrs:{"headers":_vm.headers,"items":_vm.deliveredOrders,"options":_vm.options,"show-expand":"","server-items-length":_vm.paginate.total,"loading":_vm.loading,"item-key":"name"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1",attrs:{"color":"blue","size":"40"}},[(item.attendantId)?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.attendantId.name.charAt(0)))]):_vm._e()])]}},{key:"item.lubesOrdersLineItem",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.lubesOrdersLineItem.length))])]}},{key:"item.isOrderReviewed",fn:function(ref){
var item = ref.item;
return [(item.isOrderReviewed)?_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isOrderReviewed ? '#9efb9e66' : '#f98c6b',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isOrderReviewed
                      ? 'rgba(3, 91, 3, 0.83)'
                      : '#862c00'
                  })},[_vm._v("Reviewed")])]):_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s("Not Reviewed "))])])]}},{key:"item.isOrderApproved",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isOrderApproved ? '#9efb9e66' : 'rgb(247, 189, 172)',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isOrderApproved
                      ? 'rgba(3, 91, 3, 0.83)'
                      : 'rgb(238, 78, 0)'
                  })},[_vm._v("Approved")])])]}},{key:"item.isOrderDelivered",fn:function(ref){
                  var item = ref.item;
return [_c('v-chip',{staticClass:"ft font-weight-medium mr-2 text-uppercase",attrs:{"label":"","color":item.isOrderDelivered ? '#9efb9e66' : 'rgb(247, 189, 172)',"small":""}},[_c('span',{staticClass:"font-weight-bold",style:({
                    color: item.isOrderDelivered
                      ? 'rgba(3, 91, 3, 0.83)'
                      : 'rgb(238, 78, 0)'
                  })},[_vm._v(_vm._s(item.isOrderDelivered ? "Delivered" : "Not Delivered"))])])]}}])})],1)]],2)],1),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }