<template>
  <v-container>
    <div class="ml-2"><PageTitle class="font" text="Lube Deliveries" /></div>
    <v-row align="center">
      <v-col cols="12" sm="12" class="ml-n1">
        <v-chip-group light v-model="tag" show-arrows class="font ">
          <v-chip
            label
            color="white"
            class="box-shadow-light font"
            v-for="(tag, index) in tags"
            :value="tag"
            :key="index"
            active-class="primary--text"
          >
            <span class="font-weight-bold text-uppercases">{{ tag }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="deliveredOrders"
              :options.sync="options"
              show-expand
              :server-items-length="paginate.total"
              :loading="loading"
              item-key="name"
              class="box-shadow-light font "
            >
              <template #item.avatar="{item}">
                <v-avatar color="blue" class="ma-1" size="40">
                  <span v-if="item.attendantId" class="white--text">{{
                    item.attendantId.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.lubesOrdersLineItem="{item}">
                <v-chip small label class="font-weight-bold">{{
                  item.lubesOrdersLineItem.length
                }}</v-chip>
              </template>
              <template #item.isOrderReviewed="{item}">
                <v-chip
                  v-if="item.isOrderReviewed"
                  label
                  :color="item.isOrderReviewed ? '#9efb9e66' : '#f98c6b'"
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderReviewed
                        ? 'rgba(3, 91, 3, 0.83)'
                        : '#862c00'
                    }"
                    class="font-weight-bold"
                    >Reviewed</span
                  >
                </v-chip>
                <v-chip
                  v-else
                  label
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span class="font-weight-bold">{{ "Not Reviewed " }}</span>
                </v-chip>
              </template>
              <template #item.isOrderApproved="{item}">
                <v-chip
                  label
                  :color="
                    item.isOrderApproved ? '#9efb9e66' : 'rgb(247, 189, 172)'
                  "
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderApproved
                        ? 'rgba(3, 91, 3, 0.83)'
                        : 'rgb(238, 78, 0)'
                    }"
                    class="font-weight-bold"
                    >Approved</span
                  >
                </v-chip>
              </template>
              <template #item.isOrderDelivered="{item}">
                <v-chip
                  label
                  :color="
                    item.isOrderDelivered ? '#9efb9e66' : 'rgb(247, 189, 172)'
                  "
                  class="ft font-weight-medium mr-2 text-uppercase"
                  small
                >
                  <span
                    :style="{
                      color: item.isOrderDelivered
                        ? 'rgba(3, 91, 3, 0.83)'
                        : 'rgb(238, 78, 0)'
                    }"
                    class="font-weight-bold"
                    >{{
                      item.isOrderDelivered ? "Delivered" : "Not Delivered"
                    }}</span
                  >
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import { Api } from "../../api/Api";
export default {
  name: "Deliveries",
  components: { PageTitle },
  data() {
    return {
      loading: true,
      showSnackBar: false,
      message: "",
      status: "",
      options: {},
      headers: [
        {
          text: "Date",
          value: "date"
        },
        {
          text: "Order Reference ",
          value: "orderReference"
        },
        {
          text: "Vehicle Reg.#",
          value: "vehicleRegistrationNumber"
        },
        {
          text: "Driver's Name",
          value: "driverName"
        },

        {
          text: "Order Line Items",
          value: "lubesOrdersLineItem"
        },

        {
          text: "Approval Status",
          value: "isOrderApproved"
        },
        {
          text: "Delivery Status",
          value: "isOrderDelivered"
        }
      ],
      deliveredOrders: [],
      tags: [],
      tag: "",
      paginate: {
        page: 1,
        total: 0,
        limit: 20
      }
    };
  },
  watch: {
    options: {
      handler() {
        const { itemsPerPage, page } = this.options;
        this.filterDeliveredLubes({
          page,
          limit: itemsPerPage,
          tag: this.tag
            ? moment(this.tag, "MMMM YYYY").format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD")
        });
      },
      deep: true
    },
    tag(value) {
      const date = moment(value, "MMMM YYYY").format("YYYY-MM-DD");
      this.filterDeliveredLubes({
        tag: date,
        page: 1,
        limit: this.paginate.limit
      });
    }
  },
  mounted() {},
  methods: {
    async filterDeliveredLubes({ tag, page, limit }) {
      try {
        this.loading = true;
        const response = await Api().get(
          `/orders/deliveries/lubes/list/by/${tag}?isPaginated=true&page=${page}&limit=${limit}`
        );
        if (response) {
          this.deliveredOrders = response.data.paginateObj.docs;
          const { limit, page, pages } = response.data.paginateObj;
          this.paginate = { limit, total: pages, page };
          this.loading = false;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message =
          e.message ?? "Unable to find delivered products for this month";
        this.status = "red";
        this.loading = false;
      }
    }
  },
  created() {
    this.tags = new Array(12).fill(0).map((item, index) =>
      moment()
        .month(index)
        .format("MMMM YYYY")
    );
    this.filterDeliveredLubes({
      ...this.paginate,
      tag: moment().format("YYYY-MM-DD")
    });
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
